<template>
  <div>
    <Row>
      <Col span="4">
        <Anchor show-ink container="#right" @on-select="anchorSelect">
          <AnchorLink href="#Basics" title="基础信息"/>
          <AnchorLink href="#perfect" title="补充信息"/>
          <AnchorLink href="#enclosure" title="附件信息"/>
        </Anchor>
      </Col>
      <Col span="20">
        <div id="right" :style="{height: `${height - 200}px`, overflow: 'auto'}">
          <Form ref="form" slot="main" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="110">
            <Collapse v-model="collapseValue" simple>
              <Panel id="Basics" name="1">
                基础信息
                <div slot="content">
                  <FormItem label="项目名称" prop="iname">
                    <Input v-model="form.iname" placeholder="项目名称"></Input>
                  </FormItem>
                  <FormItem label="施工许可证号" prop="licenseNum">
                    <Input v-model="form.licenseNum" placeholder="施工许可证号" search enter-button="临时生成" @on-search="autoGenerate"></Input>
                  </FormItem>
                  <FormItem label="安监号" prop="code">
                    <Input v-model="form.code" placeholder="安监号"></Input>
                  </FormItem>
                  <FormItem label="工程类型" prop="category">
                    <Select v-model="form.category" placeholder="工程类型">
                      <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
                    </Select>
                  </FormItem>
                  <FormItem label="项目阶段" prop="status">
                    <Select v-model="form.status" transfer clearable placeholder="项目阶段">
                      <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
                    </Select>
                  </FormItem>
                  <FormItem label="形象进度" prop="progress">
                    <Select v-model="form.progress" transfer clearable placeholder="形象进度">
                      <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
                    </Select>
                  </FormItem>
                  <div style="width: 100%;">
                    <FormItem label="所在地区" prop="area" style="width: 40%">
                      <ys-region changeOnSelect v-model="form.area" @on-change="getArea" placeholder="所在地区"/>
                    </FormItem>
                    <FormItem :labelWidth="0" prop="address" style="width: 60%">
                      <Input v-model="form.address" placeholder="详细地址"></Input>
                    </FormItem>
                  </div>
                  <FormItem label="经纬度" prop="lngLat" style="width: 80%">
                    <Input v-model="form.lngLat" @on-change="lngLatChange" placeholder="经纬度"></Input>
                  </FormItem>
                  <FormItem :labelWidth="0" style="width: 20%">
                    <Button type="primary" target="_blank" to="https://lbs.qq.com/getPoint/" long>手动选择经纬度</Button>
                  </FormItem>
                  <FormItem label="备注" prop="description" style="width: 100%">
                    <Input v-model="form.description" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"></Input>
                  </FormItem>
                </div>
              </Panel>
              <Panel id="perfect" name="2">
                补充信息
                <div slot="content">
                  <FormItem label="实际开工日期" prop="startReal">
                    <ys-date-picker v-model="form.startReal" style="width: 100%;" placeholder="实际开工日期"></ys-date-picker>
                  </FormItem>
                  <FormItem label="实际完工日期" prop="endReal">
                    <ys-date-picker v-model="form.endReal" style="width: 100%;" placeholder="实际完工日期"></ys-date-picker>
                  </FormItem>
                  <FormItem label="项目负责人" prop="principalName">
                    <Input v-model="form.principalName" placeholder="项目负责人"></Input>
                  </FormItem>
                  <FormItem label="负责人电话" prop="principalPhone">
                    <Input v-model="form.principalPhone" placeholder="负责人电话"></Input>
                  </FormItem>
                  <FormItem label="安责险联络员姓名" prop="safetyOfficerName">
                    <Input v-model="form.safetyOfficerName" placeholder="安责险联络员姓名"></Input>
                  </FormItem>
                  <FormItem label="安责险联络员电话" prop="safetyOfficerPhone">
                    <Input v-model="form.safetyOfficerPhone" placeholder="安责险联络员电话"></Input>
                  </FormItem>
                  <FormItem label="合同造价" prop="contractPrice">
                    <Input type="number" v-model="form.contractPrice" placeholder="合同造价"><span slot="append">元</span></Input>
                  </FormItem>
                  <FormItem label="工程造价" prop="amount">
                    <Input type="number" v-model="form.amount" placeholder="工程造价"><span slot="append">元</span></Input>
                  </FormItem>
                  <FormItem label="总面积" prop="buildArea">
                    <Input type="number" v-model="form.buildArea" placeholder="总面积"><span slot="append">㎡</span></Input>
                  </FormItem>
                  <FormItem label="总长度" prop="buildLength">
                    <Input type="number" v-model="form.buildLength" placeholder="总长度"><span slot="append">米</span></Input>
                  </FormItem>
                  <FormItem label="最高层数" prop="floorHeight">
                    <Input type="number" v-model="form.floorHeight" placeholder="最高楼层"><span slot="append">层</span></Input>
                  </FormItem>
                  <FormItem label="高度" prop="buildHeight">
                    <Input type="number" v-model="form.buildHeight" placeholder="高度"><span slot="append">米</span></Input>
                  </FormItem>
                </div>
              </Panel>
              <Panel id="enclosure" name="3">
                附件信息
                <div slot="content">
                  <FormItem label="施工许可证照片" prop="licenseFileList" style="width: 100%;text-align: center">
                    <ysUpload
                        @on-success="bLFile(arguments,'licenseFileList')"
                        :headers="{token:token}"
                        type="halfList"
                        :defaultList.sync="licenseFileList"
                        action="/web/resource/UploadFile"
                    ></ysUpload>
                  </FormItem>
                  <FormItem label="平面图" prop="floorViewFileList" style="width: 100%;text-align: center">
                    <ysUpload
                        @on-success="bLFile(arguments,'floorViewFileList')"
                        :headers="{token:token}"
                        type="halfList"
                        :defaultList.sync="floorViewFileList"
                        action="/web/resource/UploadFile"
                    ></ysUpload>
                  </FormItem>
                  <FormItem label="鸟瞰图" prop="aerialViewFileList" style="width: 100%;text-align: center">
                    <ysUpload
                        @on-success="bLFile(arguments,'aerialViewFileList')"
                        :headers="{token:token}"
                        type="halfList"
                        :defaultList.sync="aerialViewFileList"
                        action="/web/resource/UploadFile"
                    ></ysUpload>
                  </FormItem>
                </div>
              </Panel>
            </Collapse>
          </Form>
        </div>
      </Col>
    </Row>
    <div class="modalEditBtnArea" slot="bottomBtn" ref="bottomBtn">
      <Button type="primary" @click="save('onlySave')">仅保存</Button>
      <Button type="primary" @click="save('saveAndNew')">保存并新增参建单位</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {projectInfo} from "@/api/projects";

export default {
  name: 'projectEdit',
  mixins: [edit],

  data() {
    return {
      collapseValue: ['1'],
      ruleValidate: {
        code: [
          {required: true, message: '请填安监号', trigger: 'blur'}
        ],
        iname: [
          {required: true, message: '请填项目名称', trigger: 'blur'}
        ],
        licenseNum: [
          {required: true, message: '请填写施工许可证号！', trigger: 'blur'}
        ],
        area: [
          {required: true, message: '请选择所在区域', trigger: 'change'}
        ],
      },
      lnglatFlag: false,
      licenseFileList: [],
      floorViewFileList: [],
      aerialViewFileList: [],
      form: {
        iname: '',
        orgList: [],
        licenseNum: '',
        industryType: '1',
        tag: 1,
        principalName: '',
        principalPhone: '',
        safetyOfficerName: '',
        safetyOfficerPhone: '',
        category: '99',
        status: '',
        progress: '',
        startPlan: '',
        code: '',
        startReal: '',
        endPlan: '',
        endReal: '',
        id: '',
        lng: '',
        lat: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        contractPrice: '',
        amount: '',
        buildLength: '',
        buildArea: '',
        buildHeight: '',
        floorHeight: '',
        lngLat: '',
        area: '',
        description: '',
        licenseFileList: [],
        floorViewFileList: [],
        aerialViewFileList: []
      },
    }
  },
  mounted() {
    if (this.mid) {
      projectInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null;
        })
        this.licenseFileList = res.data.licenseFileList || [];
        this.floorViewFileList = res.data.floorViewFileList || [];
        this.aerialViewFileList = res.data.aerialViewFileList || [];
        this.form.licenseFileList = this.licenseFileList.map(item => item.id);
        this.form.floorViewFileList = this.floorViewFileList.map(item => item.id);
        this.form.aerialViewFileList = this.aerialViewFileList.map(item => item.id);
        this.form.area = res.data.countyCode || res.data.cityCode || res.data.provinceCode;
        this.form.lngLat = this.form.lat && this.form.lng ? (this.form.lat + ',' + this.form.lng) : '';
      })
    }
  },
  methods: {
    lngLatChange() {
      this.form.lng = this.form.lngLat.split(',')[1] || ''
      this.form.lat = this.form.lngLat.split(',')[0] || ''
    },
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
      if (data.length === 0) this.form.area = '';
    },
    bLFile(data, key) {
      this.form[key] = data[0].map(item => item.id);
    },
    autoGenerate() {
      this.form.licenseNum = `XKZ${new Date().getTime()}`
    },
    anchorSelect(href) {
      switch (href) {
        case '#Basics':
          this.collapseValue = ['1']
          break;
        case '#perfect':
          if (this.collapseValue.indexOf('2') === -1) this.collapseValue.push('2')
          break;
        case '#enclosure':
          if (this.collapseValue.indexOf('3') === -1) this.collapseValue.push('3')
          break;
      }
    },
    save(mode) {
      if (this.form.endReal && this.form.startReal && new Date(this.form.endReal).getTime() < new Date(this.form.startReal).getTime()) {
        this.$Message.error('完工日期不得小于开工日期！');
        return
      }
      switch (mode) {
        case 'onlySave':
          this.ok(this.form.id ? '/projects/Edit' : '/projects/Add')
          break;
        case 'saveAndNew':
          this.ok(this.form.id ? '/projects/Edit' : '/projects/Add', 'detail')
          break;
      }
    },
  },
}
</script>

<style lang="less" scoped>
#right {
  border-left: 1px solid #ececec;
  padding-left: 10px;
}
</style>
